import {cva} from 'class-variance-authority';

import Typography from '@/components/base/elements/Typography/Typography';
import Section from '@/components/base/layouts/Section/Section';
import {twMerge} from '@/stylesheets/twMerge';

import {getColorByCategoryRedesign} from '../utils/utils';

interface CategoryHeadingProps extends React.PropsWithChildren<{}> {
  category?: string;
  hasBottomBorder?: boolean;
  className?: string;
}

const wrapperStyles = cva('container gap-y-0 bg-transparent z-10 mb-lg', {
  variants: {
    hasBottomBorder: {
      true: 'after:border-divider-dark after:lg:border-b after:lg:pb-lg',
    },
  },
});

export default function CategoryHeading({
  category,
  children,
  hasBottomBorder,
  className,
}: CategoryHeadingProps) {
  return (
    <Section
      mode="dark"
      topSpacing="none"
      bottomSpacing="none"
      className={twMerge(wrapperStyles({hasBottomBorder}), className)}
    >
      <Typography
        as="h2"
        className={twMerge(
          'border-l-2 md:border-l-4 pl-sm text-t7 sm:text-t3',
          getColorByCategoryRedesign(category).borderColor,
        )}
        isRichtext={false}
      >
        {children}
      </Typography>
    </Section>
  );
}
