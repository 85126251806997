import {useState} from 'react';
import {useLoaderData} from '@remix-run/react';

import Section from '@/components/base/layouts/Section/Section';
import Typography from '@/components/base/elements/Typography/Typography';
import {useTranslations} from '@/hooks/useTranslations';
import Carousel from '@/components/shared/Carousel/Carousel';

import CarouselCard from './components/Carousel/CarouselCard';
import CategoryHeading from './components/CategoryHeading';
import BubbleSection from './components/BubbleSection';
import RecentStoryList from './components/RecentStoryList';
import StoryCardList from './components/StoryCardList';
import HeroVideo, {VideoComponent} from './components/HeroVideo/HeroVideo';
import LoadMoreButton from './components/LoadMoreButton';
import type {ModalVideo, NewsCardRecord} from './components/types';
import {type loader} from './server/loader.server';
import {useLoadMore} from './hooks/loadMore';
import CategoryGradient from './components/Gradients/CategoryGradient';
import Modal from './components/Modal/Modal';
export {loader} from './server/loader.server';
export {action} from './server/action.server';

export default function News() {
  const {t} = useTranslations();
  const {allArticles, videoArticle, merchantStories}: any =
    useLoaderData<typeof loader>();
  const [activeVideo, setActiveVideo] = useState<ModalVideo | null>(null);
  const carouselCardsContent = merchantStories.map(
    (cardRecord: NewsCardRecord) => {
      return {
        headingHtml: cardRecord.node.title,
        subheadHtml: cardRecord.node.excerpt,
        image: {
          src: cardRecord.node.image?.src || '',
          altText: cardRecord.node.image?.altText ?? '',
          width: cardRecord.node.image?.width || 'auto',
          height: cardRecord.node.image?.height || 'auto',
        },
        video: cardRecord.node.metafield.value,
      };
    },
  );
  const {articles, hasNextPage, onLoadMoreClick} = useLoadMore(
    allArticles.articles,
  );
  const carouselCards =
    carouselCardsContent && carouselCardsContent.length > 0
      ? carouselCardsContent.map((carouselCard: any, index: number) => (
          <div className="w-full focus-within:ring-0 px-px" key={index}>
            <CarouselCard
              key={carouselCard.headingHtml}
              onClick={() => {
                setActiveVideo({
                  type: carouselCard.videoType,
                  value: carouselCard.video,
                  image: carouselCard.image,
                });
                window.location.hash = '#/modal/video';
              }}
              isTransparent
              {...carouselCard}
            />
          </div>
        ))
      : [];

  return (
    <>
      <Section
        topSpacing="none"
        bottomSpacing="4xl"
        className="relative pt-hero-top bg-transparent noise"
      >
        <div className="container relative z-10 py-lg md:py-2xl px-0 md:px-2xl">
          <div className="text-center z-10 pb-md md:pb-3xl">
            <Typography as="h1" mode="dark" className="text-t5 sm:text-t1">
              {t('video.headingGroup.headingHtml')}
            </Typography>
            <Typography
              as="p"
              mode="dark"
              className="text-body-sm sm:text-body-lg pt-sm"
            >
              {t('video.headingGroup.subheadHtml')}
            </Typography>
          </div>
          {videoArticle && (
            <div className="w-full h-0 pb-[56.25%] rounded-3xl overflow-hidden">
              <HeroVideo
                heroVideo={videoArticle}
                handleClick={() => {
                  setActiveVideo({
                    type: videoArticle.metafield.type,
                    value: videoArticle.metafield.value,
                    image: videoArticle.image,
                  });
                  window.location.hash = '#/modal/video';
                }}
              />
            </div>
          )}
        </div>

        {!!carouselCards.length && (
          <>
            <CategoryHeading className="mb-0">
              {t('titleMerchatStories')}
            </CategoryHeading>
            <div className="container relative z-10">
              {carouselCards.length > 1 ? (
                <Carousel autoplay duration={500} controls={{mode: 'dark'}}>
                  {carouselCards}
                </Carousel>
              ) : (
                carouselCards[0]
              )}
            </div>
          </>
        )}
        <CategoryGradient />
      </Section>
      <BubbleSection>
        <CategoryHeading hasBottomBorder>
          {t('titleLatestFromShopify')}
        </CategoryHeading>
        <RecentStoryList news={articles.slice(0, 3)} hasBottomBorder />
        <StoryCardList news={articles.slice(3)} className="pt-2xl" />
        <Section
          topSpacing="none"
          bottomSpacing="none"
          className="container bg-transparent"
        >
          {hasNextPage && (
            <LoadMoreButton
              handleClick={onLoadMoreClick}
              hasNextPage={hasNextPage}
              className="border-divider-dark"
            />
          )}
        </Section>
      </BubbleSection>
      {activeVideo && (
        <Modal id="video">
          <VideoComponent
            value={activeVideo.value}
            type={activeVideo.type}
            image={activeVideo.image}
            controls
            unmute
          />
        </Modal>
      )}
    </>
  );
}
