import {cva} from 'class-variance-authority';

import Section from '@/components/base/layouts/Section/Section';
import Image from '@/components/base/elements/Image/Image';
import {TwoColumn} from '@/components/base/layouts/TwoColumn/TwoColumn';
import HeadingGroup from '@/components/base/modules/HeadingGroup/HeadingGroup';
import {twMerge} from '@/stylesheets/twMerge';
import InterfaceButton from '@/components/base/elements/InterfaceButton/InterfaceButton';
import {IconEnum} from '@/enums';
import {useMediaQuery} from '@/hooks/useMediaQuery';

const styles = cva('', {
  variants: {
    transparent: {
      true: 'bg-transparent',
    },
  },
});

export default function CarouselCard({
  headingHtml,
  subheadHtml,
  className,
  image,
  onClick,
  isTransparent,
}: any) {
  const isMobile = useMediaQuery('(max-width: 900px)');

  return (
    <Section
      mode="dark"
      topSpacing="none"
      bottomSpacing="none"
      className={twMerge(styles({transparent: isTransparent}), className)}
    >
      <TwoColumn className="z-10 gap-x-8 !mx-0">
        <TwoColumn.Col1 className="justify-center order-2 md:order-1">
          <HeadingGroup
            size="t3"
            className="md:pr-2xl"
            mode="dark"
            headingHtml={headingHtml}
            subheadHtml={subheadHtml}
          />
        </TwoColumn.Col1>
        <TwoColumn.Col2 className="md:col-start-7 justify-center sm:col-span-8 md:col-span-6 order-1 md:order-2">
          {image && (
            <div className="relative">
              <Image
                className="w-full rounded-3xl"
                loading="lazy"
                src={image.src}
              />
              <InterfaceButton
                className="absolute z-10 shadow-play-button bottom-2.5 left-2.5 md:bottom-8 md:left-8 md:w-20 md:h-20"
                size={isMobile ? 'small' : 'large'}
                onClick={onClick}
                iconProps={{
                  icon: IconEnum.PlayPause,
                }}
              />
            </div>
          )}
        </TwoColumn.Col2>
      </TwoColumn>
    </Section>
  );
}
